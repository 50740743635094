import {createEpicMiddleware} from "redux-observable";
import {configureStore} from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {rootEpic} from "./epics/root.epic";
import {miscReducer} from "./reducers/misc.reducer";
import subscribeActionMiddleware from "redux-subscribe-action";
import {userReducer} from "./reducers/user.reducer";
import {instancesReducer} from "./reducers/instances.reducer";

const epicsMiddleware = createEpicMiddleware();

export const store = configureStore({
    reducer: {
        miscState: miscReducer,
        userState: userReducer,
        instancesState: instancesReducer,
    },
    middleware: [epicsMiddleware, subscribeActionMiddleware],
});

epicsMiddleware.run(rootEpic);

export type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
