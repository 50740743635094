import {combineEpics, Epic, ofType} from "redux-observable";
import {miscActions} from "../reducers/misc.reducer";
import { catchError, from, ignoreElements, map, switchMap, tap } from 'rxjs';
import {PayloadAction} from "@reduxjs/toolkit";
import {Notification} from "../../dto/notification";
import {toast} from "../../components/AppRoot/AppRoot";
import {config} from "../../config";
import {API} from '../../services/api';

const onInit$: Epic = (action$) => action$.pipe(
    ofType(miscActions.init),
    switchMap(() => from([
        miscActions.loadBackendConfig(),
        miscActions.loadResources(),
    ])),
);

const showNotification$: Epic = (action$) => action$.pipe(
    ofType(miscActions.showNotification),
    tap((action: PayloadAction<Notification>) => {
        const notification = action.payload;
        toast({
            title: notification.title,
            description: notification.text,
            status: notification.type,
            duration: 5000,
            isClosable: true,
        });
    }),
    ignoreElements(),
);

const onError$: Epic = (action$) => action$.pipe(
    ofType(miscActions.onError),
    map((action: PayloadAction<any>) => {
        const code = action.payload.response?.status;
        console.warn("Got error:");
        console.warn(action.payload);
        if (typeof code === "number") {
            if (code === 401) {
                localStorage.setItem("lastAuth", (new Date).getTime().toString());
                window.location.href = `${config.apiHost}/oauth/login/google`;
            }
            const message = action.payload.response.data?._embedded?.errors?.[0]?.message;
            return miscActions.showNotification({
                title: "Error",
                text: `API call error (code: ${code})` + (message ? `: ${message}` : ""),
                type: "error",
            });
        } else {
            return miscActions.showNotification({
                title: "Error",
                text: `Unknown API call error`,
                type: "error",
            });
        }
    }),
);

const loadBackendConfig$: Epic = (action$) => action$.pipe(
    ofType(miscActions.loadBackendConfig),
    switchMap(() => from(API.getSpacelessConfig()).pipe(
        map((config) => miscActions.onBackendConfigLoaded(config)),
        catchError((response) => from([
            miscActions.onError(response),
        ])),
    )),
);

const loadResources$: Epic = (action$) => action$.pipe(
    ofType(miscActions.loadResources),
    switchMap(() => from(API.getResources()).pipe(
        map((resources) => miscActions.onResourcesLoaded(resources)),
        catchError((response) => from([
            miscActions.onError(response),
        ])),
    )),
);

export const miscEpics = combineEpics(
    onInit$,
    showNotification$,
    onError$,
    loadBackendConfig$,
    loadResources$,
);
