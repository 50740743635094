import styled from "styled-components";

export const LogContainer = styled.pre`
  height: 40vh;
  overflow: auto;
  background-color: darkslategrey;
  color: whitesmoke;
  padding: 16px;
  border-radius: 8px;
`;
