import { Card, CardBody } from '@chakra-ui/react';
import styled from 'styled-components';

export const Container = styled(Card)`
  flex-shrink: 0;
  min-height: 250px;
  width: 800px;
  max-height: 300px;
`;

export const CardContent = styled(CardBody)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  border-top: 1px solid #DDD;
  overflow: hidden;
`;

export const PropertiesList = styled.ul`
  padding-left: 20px;
  max-height: 100%;
  overflow: auto;
`;
