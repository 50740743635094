import styled from "styled-components";
import {Card, CardBody} from "@chakra-ui/react";

export const Container = styled(Card)`
  flex-shrink: 0;
  min-height: 250px;
  width: 1000px;
`;

export const CardContent = styled(CardBody)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  border-top: 1px solid #DDD;
`;

export const List = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 6px;
  margin: 8px 0 16px;
`;

export const NoInstancesLabel = styled.p`
  margin: 10px auto;
  color: #CCC;
  font-style: italic;
  font-weight: 200;
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
  align-content: center;
  justify-content: center;
`;
