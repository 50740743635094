import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {API} from "../../services/api";
import {Button, CardHeader, Heading, Spinner, Text, Tooltip} from "@chakra-ui/react";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {instancesActions} from "../../redux/reducers/instances.reducer";
import {Instance} from "../Instance/Instance";
import { ResourcesList } from '../ResourcesList/ResourcesList';
import { miscActions } from '../../redux/reducers/misc.reducer';

export const InstancesList: React.FC = () => {

    const instances = useAppSelector(state => state.instancesState.runningInstances);
    const failure = useAppSelector(state => state.instancesState.failedToLoad);
    const showAdvancedComponents = useAppSelector(state => state.miscState.showAdvancedComponents);

    const dispatch = useAppDispatch();

    const [wiping, setWiping] = useState(false);

    useEffect(() => {
        reload();
        const interval = setInterval(reload, 20000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => setWiping(false), [instances?.map(i => i.jobName).join("")]);

    function reload() {
        dispatch(instancesActions.loadInstances());
        if (showAdvancedComponents) {
            dispatch(miscActions.loadResources());
        }
    }

    async function wipe() {
        setWiping(true);
        await API.terminateAll();
        setTimeout(() => dispatch(instancesActions.loadInstances()), 1000);
    }

    return <S.Container>
        <CardHeader>
            <Heading size="sm">Running instances</Heading>
        </CardHeader>
        <S.CardContent>
            {showAdvancedComponents && <ResourcesList/>}
            {instances === null && <div>
                <Spinner style={{margin: "auto", display: "block", marginTop: "50px"}}/>
                {failure && <p style={{textAlign: "center", margin: "8px 0"}}>Failed to load instances, retrying...</p>}
            </div>}
            {instances !== null && instances.length > 0 && <>
                <Text>User instances:</Text>
                <S.List>
                    {instances.filter(instance => !instance.isInPool).map(instance => <Instance instanceData={instance} key={instance.podName}/>)}
                </S.List>
                {instances.filter(instance => !instance.isInPool).length === 0 && <S.NoInstancesLabel>No user instances</S.NoInstancesLabel>}
                <Text>Pool instances:</Text>
                <S.List>
                    {instances.filter(instance => instance.isInPool).map(instance => <Instance instanceData={instance} key={instance.podName}/>)}
                </S.List>
                {instances.filter(instance => instance.isInPool).length === 0 && <S.NoInstancesLabel>No pool instances</S.NoInstancesLabel>}
                <S.ButtonsContainer>
                    <Button
                        colorScheme="messenger"
                        style={{minWidth: "140px"}}
                        onClick={reload}
                    >
                        Refresh
                    </Button>
                    <Tooltip label="Delete all (not only yours) instances">
                        <Button
                            onClick={wipe}
                            isLoading={wiping}
                            loadingText="Wiping"
                            colorScheme="red"
                            style={{minWidth: "140px"}}
                        >
                            Wipe all
                        </Button>
                    </Tooltip>
                </S.ButtonsContainer>
            </>}
        </S.CardContent>

    </S.Container>;

};
