export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const uploadFile: () => Promise<string> = async () => {
    const input = document.createElement("input");
    input.type = "file";
    input.click();
    return new Promise(resolve => {
        input.onchange = () => {
            const file = input.files![0];
            file.text().then(resolve);
        };
    });
};

export function downloadStringAsFile(content: string, filename: string): void {
    const element = document.createElement('a');
    const file = new Blob([content], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
}
