import styled from "@emotion/styled";

export const Layout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
`;

export const Main = styled.main`
  padding: 16px;
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;
