import React from 'react';
import * as S from "./styles";
import { CardHeader, Heading } from '@chakra-ui/react';
import { useAppSelector } from '../../redux/store';

export const ConfigInfo: React.FC = () => {

  const config = useAppSelector(state => state.miscState.backendConfig);
  const poolConfig = config?.poolConfig ?? null;

  return <S.Container>
    <CardHeader>
      <Heading size="sm">
        Spaceless parameters
      </Heading>
    </CardHeader>
    <S.CardContent>
      {config
        ? <S.PropertiesList>
          <li><b>Autoscaling master node:</b> {config!.masterNode ?? "(none)"}</li>
          <li><b>Desired pool ratio:</b> {poolConfig!.desiredPoolRatio}%</li>
          <li><b>Min pool size:</b> {poolConfig!.minPoolSize}</li>
          <li><b>Max pool size:</b> {poolConfig!.maxPoolSize}</li>
          <li><b>Out-of-cluster autoscaling:</b> {String(poolConfig!.outOfClusterAutoscaling)}</li>
          <li><b>GPU support:</b> {config.gpuSupport ? "On" : "Off"}</li>
        </S.PropertiesList>
        : <p>Loading config...</p>
      }
    </S.CardContent>
  </S.Container>

};
