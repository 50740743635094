import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { HistoryItem } from '../../dto/history-item';

interface UserState {
    createdWorkspaces: HistoryItem[];
}

const WORKSPACES_KEY = "workspacesHistory";

const initialState: UserState = {
    createdWorkspaces: getWorkspacesFromLocalstorage(),
};

const slice = createSlice({
    name: "user",
    initialState,
    reducers: {
        addWorkspaceToHistory: (state, action: PayloadAction<HistoryItem>) => {
            const updated = [action.payload, ...state.createdWorkspaces.slice(0, 20)];
            state.createdWorkspaces = updated;
            localStorage.setItem(WORKSPACES_KEY, JSON.stringify(updated));
        },
    },
});

export const userReducer = slice.reducer;
export const userActions = slice.actions;

function getWorkspacesFromLocalstorage(): HistoryItem[] {
    const str = localStorage.getItem(WORKSPACES_KEY);
    return str ? JSON.parse(str) : [];
}
