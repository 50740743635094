import styled from "styled-components";

export const ResourcesAdvice = styled.p`
  background-color: #4a6067;
  font-size: 0.8em;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 12px;
`;

export const MemoryInfo = styled.p`
  font-size: 0.8em;
  margin: 6px 0 10px;
  text-align: center;
`;

export const FileContainer = styled.div`
  margin: 4px 0;
  padding: 4px;
  border: 1px solid #4E5766;
  display: flex;
  flex-flow: column nowrap;
  gap: 4px;
`;
