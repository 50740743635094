import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Notification} from "../../dto/notification";
import { SpacelessConfig } from '../../dto/spaceless-config';
import { JipQuota } from '../../dto/jip-quota';

interface MiscReducer {
    showAdvancedComponents: boolean;
    useToolboxApiLinks: boolean;
    backendConfig: SpacelessConfig | null;
    resources: JipQuota | null;
    rootMode: boolean;
}

const initialState: MiscReducer = {
    showAdvancedComponents: localStorage.getItem("SPACELESS_SHOW_ADVANCED_ELEMENTS") === "true",
    useToolboxApiLinks: localStorage.getItem("SPACELESS_USE_TOOLBOX_API_LINKS") === "true",
    backendConfig: null,
    resources: null,
    rootMode: localStorage.getItem("SPACELESS_ROOT_MODE") === "true",
};

const slice = createSlice({
    name: "misc",
    initialState,
    reducers: {
        init: () => {},
        showNotification: (_, action: PayloadAction<Notification>) => {},
        onError: (state, action: PayloadAction<any>) => {},
        setUseToolboxApiLinks: (state, action: PayloadAction<boolean>) => {
            localStorage.setItem("SPACELESS_USE_TOOLBOX_API_LINKS", String(action.payload));
            state.useToolboxApiLinks = action.payload;
        },
        setShowAdvancedComponents: (state, action: PayloadAction<boolean>) => {
            localStorage.setItem("SPACELESS_SHOW_ADVANCED_ELEMENTS", String(action.payload));
            state.showAdvancedComponents = action.payload;
        },
        loadBackendConfig: () => {},
        onBackendConfigLoaded: (state, action: PayloadAction<SpacelessConfig>) => {
            state.backendConfig = action.payload;
        },
        loadResources: () => {},
        onResourcesLoaded: (state, action: PayloadAction<JipQuota>) => {
            state.resources = action.payload;
        },
        setRootMode: (state, action: PayloadAction<boolean>) => {
            localStorage.setItem("SPACELESS_ROOT_MODE", String(action.payload));
            state.rootMode = action.payload;
        }
    },
});

export const miscReducer = slice.reducer;
export const miscActions = slice.actions;
