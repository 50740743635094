import styled from "styled-components";

export const Container = styled.header`
    background: rgb(49, 151, 149);
    background: linear-gradient(90deg, rgb(0, 0, 0) 0%, #7ebc73 100%);
    height: 48px;
    width: 100%;
    line-height: 48px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 4px;
`;

export const Title = styled.h1`
  margin: 0 16px;
  font-size: 1.4em;
  font-weight: 200;
  color: whitesmoke;
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 4px;
`;
