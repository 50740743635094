import styled from "styled-components";
import {Tag as ChakraTag} from "@chakra-ui/react";

export const JobsListItem = styled.div`
  background-color: #384761;
  padding: 8px;
  border-radius: 6px;
  width: 100%;
  margin: auto;
`;

export const Tag = styled(ChakraTag)`
  white-space: nowrap;
  overflow: hidden;
`;

export const DataRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
  justify-content: center;
  cursor: pointer;
`;

export const ToggleableContent = styled.div<{expanded: boolean}>`
  padding: ${props => props.expanded ? `12px` : `0`};
  display: flex;
  flex-flow: column nowrap;
  gap: 4px;
  overflow: hidden;
  height: ${props => props.expanded ? `180px` : `0`};
  transition: height ease-in-out 0.2s, padding ease-in-out 0.2s;
`;

export const Value = styled.code`
  //display: inline-block;
  font-size: 0.9em;
  color: white;
  background-color: #1D232C;
  padding: 4px;
  border-radius: 4px;
`;
