import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {AppRoot} from './components/AppRoot/AppRoot';
import reportWebVitals from './reportWebVitals';
import {ChakraProvider, DarkMode} from "@chakra-ui/react";
import {Provider} from "react-redux";
import {store} from "./redux/store";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <ChakraProvider>
            <DarkMode>
                <Provider store={store}>
                    <AppRoot/>
                </Provider>
            </DarkMode>
        </ChakraProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
