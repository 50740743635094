import styled from "@emotion/styled";
import {Card, CardBody} from "@chakra-ui/react";

export const Container = styled(Card)`
  flex-shrink: 0;
  min-height: 250px;
  width: 800px;
  max-height: 300px;
`;

export const CardContent = styled(CardBody)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  border-top: 1px solid #DDD;
  overflow: hidden;
`;

export const List = styled.ul`
  padding-left: 20px;
  max-height: 100%;
  overflow: auto;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
  justify-content: center;
  align-content: center;
  & button {
    min-width: 140px;
  }
`;
