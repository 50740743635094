import React, { useEffect, useState } from 'react';
import * as S from './styles';
import {
  Alert, AlertIcon, AlertTitle,
  Button,
  ButtonGroup,
  CardHeader,
  Heading,
  IconButton,
  Input,
  Select,
  Stack,
  Text
} from '@chakra-ui/react';
import { PodFile, Resources } from '../../dto/run-instance-request';
import { ExternalLinkIcon, SettingsIcon, TimeIcon } from '@chakra-ui/icons';
import { InstanceSettingsModal } from '../InstanceSettingsModal/InstanceSettingsModal';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userActions } from '../../redux/reducers/user.reducer';
import { instancesActions, OnInstanceStartedActionPayload } from '../../redux/reducers/instances.reducer';
import { subscribeActionAfter } from 'redux-subscribe-action';
import { PayloadAction } from '@reduxjs/toolkit';
import { HistoryItem } from '../../dto/history-item';
import { HistoryModal } from '../HistoryModal/HistoryModal';
import { API } from '../../services/api';

const AVAILABLE_IMAGES: { image: string, label: string, gpuRequired?: boolean }[] = [
  {
    image: 'gcr.io/jetbrains-academy-dev/edurd/ma-dev-gpu-support-test-23986:6',
    label: 'paid course (24.10.24)',
    gpuRequired: true,
  },
  {
    image: 'gcr.io/jetbrains-academy-dev/edurd/ma-dev-gpu-support-test-trial-24922:3',
    label: 'paid course trial (24.10.24)',
    gpuRequired: true,
  },
  {
    image: 'gcr.io/jetbrains-academy-dev/edurd/ma-dev-johny-23986-23986@sha256:3baf5c159bc308288ce243b7d47bfb5e6882fd04caef0e71b5b5125df5226afa',
    label: 'paid course (old)',
  },
  { image: 'gcr.io/jetbrains-academy-dev/edurd/dev-kotlin-intro-ui-fix-21067:latest', label: 'kotlin intro (ui fix)' },
  { image: 'vladfedorenkov/lightweight-runtime-emulator:6', label: '* runtime emulator (spaceless debug)' },
];

const UID_LS_KEY = 'uid';

interface CreatingInstanceData {
  image: string;
  workspaceId: string;
  requestedAt: Date;
  uid: string;
  username: string;
}

export const InstanceControls: React.FC = () => {

  const loading = useAppSelector(state => state.instancesState.creatingInstance);
  const instances = useAppSelector(state => state.instancesState.runningInstances);
  const toolboxApiLink = useAppSelector(state => state.miscState.useToolboxApiLinks);
  const [loadingTimer, setLoadingTimer] = useState<NodeJS.Timer | null>(null);
  const gpuAvailable = useAppSelector(state => state.miscState.backendConfig?.gpuSupport === true);

  const dispatch = useAppDispatch();

  const [timer, setTimer] = useState(0);
  const [result, setResult] = useState<OnInstanceStartedActionPayload | null>(null);
  const [imageDropdownValue, setImageDropdownValue] = useState(AVAILABLE_IMAGES[0].image);
  const [selectedImage, setSelectedImage] = useState(imageDropdownValue);
  const gpuIsRequired = AVAILABLE_IMAGES.some(i => i.image === selectedImage && i.gpuRequired);
  const [resources, setResources] = useState<Resources>({ mcpu: 2000, memory: 4000, gpu: 0 });
  const [files, setFiles] = useState<PodFile[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [workspaceId, setWorkspaceId] = useState(generateWorkspaceId());
  const [uid, setUid] = useState(localStorage.getItem(UID_LS_KEY) ?? '');
  const [username, setUsername] = useState("anonymous");

  const [showHistory, setShowHistory] = useState(false);
  const [creatingData, setCreatingData] = useState<CreatingInstanceData | null>(null);

  const startAllowed = selectedImage.trim() !== '' && uid.trim() !== '' && workspaceId.trim() !== '' && username.trim() !== '';

  useEffect(() => subscribeActionAfter(instancesActions.onInstanceStarted.type, (action) => {
    setResult((action as PayloadAction<OnInstanceStartedActionPayload>).payload);
    loadingTimer && clearInterval(loadingTimer) || console.warn('No timer to reset', loadingTimer);
    setWorkspaceId(generateWorkspaceId()); // generate a new one for next instances
  }), [timer]);

  useEffect(() => {
    subscribeActionAfter(instancesActions.onInstanceStartFailure.type, () => {
      setTimer(0);
      if (loadingTimer) {
        clearInterval(loadingTimer);
        setLoadingTimer(null);
      }
    });
    API.getCurrentUserInfo().then(data => {
      const email = data.email;
      setUsername(email.replace(/@.*/, ""));
    });
  }, []);

  useEffect(() => {
    const instance = instances?.find(i => i.workspaceId === creatingData?.workspaceId);
    if (instance !== undefined) {
      const historyItem: HistoryItem = {
        workspaceId: instance.workspaceId,
        podName: instance.podName,
        image: instance.image,
        mcpu: instance.mcpu,
        memory: instance.memory,
        gpu: instance.gpu,
        requestedAt: creatingData!.requestedAt.toLocaleString('ru-RU'),
        uid: creatingData!.uid,
        username: creatingData!.username,
      };
      dispatch(userActions.addWorkspaceToHistory(historyItem));
      setCreatingData(null);
    }
  }, [instances]);

  useEffect(() => {
    const knownImage = AVAILABLE_IMAGES.find(i => i.image === selectedImage);
    if (knownImage) {
      setResources(prev => ({ ...prev, gpu: knownImage.gpuRequired && gpuAvailable ? 1 : 0 }));
    }
  }, [selectedImage, gpuAvailable]);

  function recreate(id: string, image: string, mcpu: number, memory: number, gpu: number, uid: string, username: string) {
    setShowHistory(false);
    run(image, {mcpu, memory, gpu}, id, uid, username);
  }

  function run(image: string, resources: Resources, workspaceId: string, uid: string, username: string) {
    if (image === '') {
      throw Error();
    }
    const interval = setInterval(() => {
      setTimer(curr => curr + 100);
    }, 100);
    setLoadingTimer(interval);
    dispatch(instancesActions.runInstance({
      dockerImage: image,
      resources,
      workspaceId,
      uid,
      username,
      legacyJoinLinks: !toolboxApiLink,
      customAnnotations: {
        startedFrom: "spaceless-ui",  // not really needed, just for fun actually
      },
      files,
    }));
    setCreatingData({
      image,
      workspaceId,
      requestedAt: new Date(),
      uid,
      username,
    });
  }

  async function connect() {
    const link = result!.connectionLink;
    window.open(link, '_blank')!.focus();
  }

  function saveResourcesSettings(mcpu: number, memory: number, gpu: boolean, files: PodFile[]) {
    setResources({ mcpu, memory, gpu: gpu ? 1 : 0 });
    setShowModal(false);
    setFiles(files);
  }

  function reset() {
    setTimer(0);
    setResult(null);
  }

  function onDropdownChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const value = e.target.value;
    setImageDropdownValue(value);
    if (value !== 'custom') {
      setSelectedImage(value);
    }
  }

  function onUidChange(value: string) {
    const sanitizedValue = value.replace(/[^-a-zA-Z0-9]/g, '');
    setUid(sanitizedValue);
    localStorage.setItem(UID_LS_KEY, sanitizedValue);
  }

  const button = result === null
    ? <div>
      <Text>Runtime image:</Text>
      <Select placeholder="Select project" value={imageDropdownValue} onChange={onDropdownChange}
              style={{ marginBottom: '4px' }}>
        {[...AVAILABLE_IMAGES, { label: 'Custom', image: 'custom' }].map(imageDescriptor => <option
          key={imageDescriptor.image} value={imageDescriptor.image}>
          {imageDescriptor.label}
        </option>)}
      </Select>
      {imageDropdownValue === 'custom' && <Input
          placeholder="Custom image name"
          value={selectedImage}
          onChange={e => setSelectedImage(e.target.value.trim())}
      />}
      <div style={{ marginTop: '16px' }}>
        <Text>Preferred workspaceId:</Text>
        <Input
          placeholder="workspaceId"
          value={workspaceId}
          onChange={e => setWorkspaceId(e.target.value.trim())}
        />
      </div>
      <div style={{ margin: '16px 0' }}>
        <Text>
          Your UID:
          <IconButton
              aria-label="get id"
              icon={<ExternalLinkIcon/>}
              size="xs"
              style={{margin: "4px"}}
              onClick={() => window.open("https://staging.academy.labs.jb.gg/api/users/me", "_blank")}
          />
        </Text>
        <Input
          placeholder="UID"
          value={uid}
          onChange={e => onUidChange(e.target.value.trim())}
        />
      </div>

      {gpuIsRequired && resources.gpu === 0 && <Alert status="warning">
        <AlertIcon/>
        <AlertTitle>This course requires a GPU</AlertTitle>
      </Alert>}

      <ButtonGroup isAttached style={{ marginTop: "16px", width: "100%" }} colorScheme="messenger">
        <Button
          onClick={() => run(selectedImage, resources, workspaceId, uid, username)}
          isLoading={loading}
          loadingText={`Starting (${(timer / 1000).toFixed(2)}s)`}
          style={{ minWidth: '200px', fontFamily: '\'Inconsolata\', monospace', flexGrow: 1 }}
          isDisabled={!startAllowed}
        >
          Run new backend
        </Button>
        <IconButton
          onClick={() => setShowHistory(true)}
          aria-label="resources settings"
          icon={<TimeIcon/>}
        />
        <IconButton
          onClick={() => setShowModal(true)}
          aria-label="resources settings"
          icon={<SettingsIcon/>}
          isDisabled={loading}
        />
      </ButtonGroup>
    </div>
    : <Stack direction="column">
      <Button onClick={connect} colorScheme="whatsapp" style={{ minWidth: '200px' }}>
        Connect
      </Button>
      <Button colorScheme="whatsapp" variant="ghost" onClick={reset}>
        Start one more
      </Button>
    </Stack>;

  return <S.Layout>
    <CardHeader>
      <Heading size="sm">
        New instance
      </Heading>
    </CardHeader>
    <S.CardContent>
      {button}
      {result && <>
          <S.KekLabel>Started in {(timer / 1000).toFixed(2)}s</S.KekLabel>
      </>}
      <InstanceSettingsModal
        currentMcpu={resources.mcpu}
        currentMemory={resources.memory}
        currentGpu={resources.gpu !== 0}
        open={showModal}
        onClose={() => setShowModal(false)}
        onSave={saveResourcesSettings}
      />
    </S.CardContent>

    <HistoryModal open={showHistory} onClose={() => setShowHistory(false)} onRecreate={recreate}/>
  </S.Layout>;

};

function generateWorkspaceId(): string {
  // return "-";
  return 'sample-ws-' + Date.now();
}
