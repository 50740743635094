import styled from "styled-components";
import {Card, CardBody} from "@chakra-ui/react";

export const Layout = styled(Card)`
  flex-shrink: 0;
  width: 350px;
  min-height: 250px;
  overflow: hidden;
`;

export const CardContent = styled(CardBody)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #DDD;
`;

export const KekLabel = styled.p`
  margin-top: 12px;
  font-size: 0.8em;
`;

