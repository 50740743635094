import styled from '@emotion/styled';

export const List = styled.ol`
  padding-left: 20px;
`;

export const ButtonsList = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 4px;
`;
