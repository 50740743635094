import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {Button, CardHeader, Heading} from "@chakra-ui/react";
import {GetRecentlyUsedImagesResponse} from "../../dto/get-recently-used-images-response";
import {API} from "../../services/api";
import {useAppDispatch} from "../../redux/store";
import {miscActions} from "../../redux/reducers/misc.reducer";

export const RecentImagesList: React.FC = () => {

    const [data, setData] = useState<GetRecentlyUsedImagesResponse | null>(null);
    const dispatch = useAppDispatch();

    const [wiping, setWiping] = useState(false);

    useEffect(() => refresh(), []);

    function refresh() {
        API.getRecentlyUsedImages().then(setData);
    }

    async function wipe() {
        setWiping(true);
        await API.wipeCache();
        setWiping(false);
        dispatch(miscActions.showNotification({
            title: "Wiped",
            text: "The Redis cache was flushed",
            type: "success",
        }));
        refresh();
    }

    return <S.Container>
        <CardHeader>
            <Heading size="sm">
                Recently used images
            </Heading>
        </CardHeader>
        <S.CardContent>
            {data?.history && data.history.length > 0
                && <S.List>
                    {data.history.map((item, idx) => <li key={idx}>{item.image} ({item.mcpu ?? "?"}mcpu | {item.memory ?? "?"}M | {item.gpu}gpu)</li>)}
                </S.List>
                || <p>[No data]</p>
            }
            <S.ButtonsContainer>
                <Button onClick={refresh}>
                    Refresh
                </Button>
                <Button onClick={wipe} isLoading={wiping}>
                    Wipe cache
                </Button>
            </S.ButtonsContainer>
        </S.CardContent>
    </S.Container>;

};
