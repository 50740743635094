import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
`;

export const LabelColumn = styled.td`
  width: 140px;
`;

export const ProgressColumn = styled.td`
  width: auto;
`;