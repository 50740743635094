import React from 'react';
import {
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { miscActions } from '../../redux/reducers/misc.reducer';

interface Props {
  open: boolean;
  onClose(): void;
}

export const UiSettingsModal: React.FC<Props> = (props) => {

  const { open, onClose } = props;

  const dispatch = useAppDispatch();
  const { useToolboxApiLinks, showAdvancedComponents, rootMode } = useAppSelector(state => state.miscState);

  function toggleToolboxApi() {
    dispatch(miscActions.setUseToolboxApiLinks(!useToolboxApiLinks));
  }

  function toggleAdvancedComponents() {
    dispatch(miscActions.setShowAdvancedComponents(!showAdvancedComponents));
  }

  function toggleRootMode() {
    dispatch(miscActions.setRootMode(!rootMode));
  }

  return <Modal isOpen={open} onClose={onClose}>
    <ModalOverlay/>
    <ModalContent>
      <ModalHeader>Settings</ModalHeader>
      <ModalCloseButton/>
      <ModalBody>
        <div style={{ paddingBottom: '10px' }}>
          <Checkbox defaultChecked={useToolboxApiLinks} checked={useToolboxApiLinks} onChange={toggleToolboxApi}>
            Use experimental Toolbox API links
          </Checkbox>
        </div>
        <div style={{ paddingBottom: '10px' }}>
          <Checkbox defaultChecked={showAdvancedComponents} checked={showAdvancedComponents} onChange={toggleAdvancedComponents}>
            Show advanced components
          </Checkbox>
        </div>
        <div style={{ paddingBottom: '10px' }}>
          <Checkbox defaultChecked={rootMode} checked={rootMode} onChange={toggleRootMode}>
            Root mode
          </Checkbox>
        </div>
      </ModalBody>
    </ModalContent>
  </Modal>;

};
