import React, { useState } from 'react';
import * as S from "./styles";
import {SiSemanticuireact} from "react-icons/si";
import { Button } from '@chakra-ui/react';
import { ExternalLinkIcon, QuestionOutlineIcon, SettingsIcon } from '@chakra-ui/icons';
import { UiSettingsModal } from '../UiSettingsModal/UiSettingsModal';
import { HowToModal } from '../HowToModal/HowToModal';

export const Header: React.FC = () => {

    const [showSettings, setShowSettings] = useState(false);
    const [showHowTo, setShowHowTo] = useState(false);

    function openLogs() {
        window.open("https://grafana.intellij.net/d/nWt4hnGSk/spaceless", "_blank");
    }

    return <>
        <S.Container>
            <S.Title><SiSemanticuireact/> SPACELESS</S.Title>
            <S.ButtonsContainer>
                <Button leftIcon={<QuestionOutlineIcon/>} colorScheme="whatsapp" onClick={() => setShowHowTo(true)}>How To Connect</Button>
                <Button leftIcon={<SettingsIcon/>} colorScheme="whatsapp" onClick={() => setShowSettings(true)}>Settings</Button>
                <Button leftIcon={<ExternalLinkIcon/>} colorScheme="whatsapp" onClick={openLogs}>Grafana Logs</Button>
            </S.ButtonsContainer>
        </S.Container>

        <UiSettingsModal open={showSettings} onClose={() => setShowSettings(false)}/>
        <HowToModal open={showHowTo} onClose={() => setShowHowTo(false)}/>
    </>;

};
