import React from "react";
import * as S from "./styles";
import {
    Button,
    Heading,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';

interface Props {
    open: boolean;
    onClose(): void;
}

export const HowToModal: React.FC<Props> = (props) => {

    const { open, onClose } = props;

    function openDownloadLink(type: "win" | "macos"): void {
        switch (type) {
            case "win": {
                window.open("https://buildserver.labs.intellij.net/buildConfiguration/ijplatform_master_Toolbox_BuildWindows?branch=%3Cdefault%3E&buildTypeTab=overview", "_blank");
                break;
            }
            case "macos": {
                window.open("https://buildserver.labs.intellij.net/buildConfiguration/ijplatform_master_Toolbox_BuildMacOs?branch=%3Cdefault%3E&buildTypeTab=overview", "_blank");
                break;
            }
        }
    }

    return <Modal isOpen={open} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent>
            <ModalHeader>How To Connect</ModalHeader>
            <ModalCloseButton/>
            <ModalBody>
                <Heading as="h2" size="xs">Current solution:</Heading>
                <S.List style={{ marginBottom: '25px' }}>
                    <li>
                        <p>Install the Toolbox v.2.5.0+</p>
                        <S.ButtonsList>
                            <Button
                                onClick={() => openDownloadLink('win')}
                                leftIcon={<DownloadIcon/>}
                            >
                                WIN [nightly]
                            </Button>
                            <Button
                                onClick={() => openDownloadLink('macos')}
                                leftIcon={<DownloadIcon/>}
                            >
                                MACOS [nightly]
                            </Button>
                        </S.ButtonsList>
                    </li>
                    <li>Make sure that Spaceless setting "Use experimental Toolbox API links" is <b>enabled</b></li>
                </S.List>

                <Heading as="h2" size="xs">Legacy solution:</Heading>
                <S.List>
                <li>Install the JetBrains Gateway</li>
                    <li>
                        <p>Download the Gateway plugin:</p>
                        <iframe width="245px" height="48px" src="https://plugins.jetbrains.com/embeddable/install/22692"></iframe>
                    </li>
                    <li>Install the plugin manually and restart the Gateway</li>
                    <li>Make sure that Spaceless setting "Use experimental Toolbox API links" is <b>disabled</b></li>
                </S.List>
            </ModalBody>
        </ModalContent>
    </Modal>;

}