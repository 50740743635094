import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text
} from "@chakra-ui/react";
import {API} from "../../services/api";

interface Props {
    workspaceId: string;
    onClose(): void;
}

export const DebugInfoModal: React.FC<Props> = (props) => {

    const {workspaceId, onClose,} = props;
    const [log, setLog] = useState<string | null>(null);

    useEffect(() => {
        updateLog();
        const interval = setInterval(() => {
            updateLog();
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    async function updateLog() {
        const log = await API.getInstanceLogs(workspaceId);
        setLog(log);
    }

    return <Modal isOpen={true} onClose={onClose} size="6xl">
        <ModalOverlay/>
        <ModalContent>
            <ModalHeader>Debug info</ModalHeader>
            <ModalCloseButton/>
            <ModalBody>
                {log && <S.LogContainer>
                    {log}
                </S.LogContainer> || <Text>No logs available for this workspace</Text>}
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='blue' variant="outline" mr={3} onClick={onClose}>
                    Close
                </Button>
            </ModalFooter>
        </ModalContent>
    </Modal>;

};
