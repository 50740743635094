import React, {useState} from "react";
import {ComputedPodStatus, InstanceData} from "../../dto/get-instances-response";
import {IconButton, Spinner, Tag, Tooltip} from "@chakra-ui/react";
import {LinkIcon} from "@chakra-ui/icons";
import {VscDebugConsole} from "react-icons/vsc";
import {BsStopFill} from "react-icons/bs";
import * as S from "./styles";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {API} from "../../services/api";
import {instancesActions} from "../../redux/reducers/instances.reducer";
import {DebugInfoModal} from "../DebugInfoModal/DebugInfoModal";
import { downloadStringAsFile } from '../../misc/utils';

interface Props {
    instanceData: InstanceData;
}

export const Instance: React.FC<Props> = (props) => {

    const instance = props.instanceData;

    const dispatch = useAppDispatch();
    const knownInstances = useAppSelector(state => state.userState.createdWorkspaces);
    const {useToolboxApiLinks, rootMode} = useAppSelector(state => state.miscState);

    const [workspaceIdForLogsModal, setWorkspaceIdForLogsModal] = useState<string | null>(null);
    const [expanded, setExpanded] = useState(false);

    async function connect(instance: InstanceData) {
        const link = await API.getLink(instance.workspaceId, !useToolboxApiLinks);
        window.open(link, "_blank");
    }

    function stopInstance(instance: InstanceData) {
        dispatch(instancesActions.stopInstance({workspaceId: instance.workspaceId}));
    }

    async function downloadIdeLogs(instance: InstanceData): Promise<void> {
        const response = await API.getIdeLogs(instance.workspaceId);
        if (response.success) {
            downloadStringAsFile(response.content!, "idea.log");
        }
    }

    return <>
        <S.JobsListItem key={instance.jobName}>
            <S.DataRow onClick={() => setExpanded(!expanded)}>
                <Tag colorScheme={resolveStatusTagColor(instance.status)}>{instance.status}</Tag>
                <Tag>{instance.ip ?? <Spinner size="xs"/>}</Tag>
                <S.Tag style={{flex: "1 0"}}>{instance.shortImageName} @ {instance.labels.workspaceId ?? "[unknown WSID]"}</S.Tag>
                <Tooltip label="CPUs/Memory/GPUs">
                    <Tag>{instance.mcpu}mcpu/{instance.memory}mb/{instance.gpu}</Tag>
                </Tooltip>
                <Tooltip label="Reconnect">
                    <IconButton
                      aria-label="connect"
                      isDisabled={!rootMode && !knownInstances.some(i => i.workspaceId === instance.workspaceId)}
                      icon={<LinkIcon/>}
                      onClick={(e) => {
                          connect(instance);
                          e.stopPropagation();
                      }}
                      size={"xs"}
                    />
                </Tooltip>
                <Tooltip label="Get pod logs">
                    <IconButton
                      aria-label="get pod logs"
                      isDisabled={!rootMode && !knownInstances.some(i => i.workspaceId === instance.workspaceId)}
                      icon={<VscDebugConsole/>}
                      onClick={(e) => {
                          setWorkspaceIdForLogsModal(instance.workspaceId);
                          e.stopPropagation();
                      }}
                      size="xs"
                    />
                </Tooltip>
                <Tooltip label="Get IDE logs">
                    <IconButton
                        aria-label="get ide logs"
                        isDisabled={!rootMode && !knownInstances.some(i => i.workspaceId === instance.workspaceId)}
                        icon={<VscDebugConsole/>}
                        onClick={async (e) => {
                            downloadIdeLogs(instance);
                            e.stopPropagation();
                        }}
                        size="xs"
                    />
                </Tooltip>
                <Tooltip label="Stop instance">
                    <IconButton
                      aria-label="stop"
                      icon={<BsStopFill/>}
                      onClick={(e) => {
                          stopInstance(instance);
                          e.stopPropagation();
                      }}
                      size={"xs"}
                      colorScheme="red"
                      isDisabled={instance.status === ComputedPodStatus.KILLING}
                    />
                </Tooltip>
            </S.DataRow>
            <S.ToggleableContent expanded={expanded}>
                <p>Pod: <S.Value>{instance.podName}</S.Value></p>
                <p>Pod created: <S.Value>{instance.startedAt.toLocaleString('ru-RU')}</S.Value></p>
                <p>Image: <S.Value>{instance.image.replace(/@.*$/, "")}</S.Value></p>
                <p>WSID: <S.Value>{instance.labels.workspaceId}</S.Value></p>
                <p>UID: <S.Value>{instance.labels.jbUid ?? "[unset]"}</S.Value></p>
                <p>Username: <S.Value>{instance.labels.username ?? "[unset]"}</S.Value></p>
            </S.ToggleableContent>
        </S.JobsListItem>
        {workspaceIdForLogsModal && <DebugInfoModal workspaceId={workspaceIdForLogsModal} onClose={() => setWorkspaceIdForLogsModal(null)}/>}
    </>;

};

function resolveStatusTagColor(status: ComputedPodStatus): string {
    switch (status) {
        case ComputedPodStatus.RUNNING:
            return "whatsapp";
        case ComputedPodStatus.SCALING:
        case ComputedPodStatus.PULLING:
            return "yellow";
        case ComputedPodStatus.PENDING:
            return "orange";
        case ComputedPodStatus.KILLING:
            return "facebook";
        case ComputedPodStatus.UNKNOWN:
            return "red";
        default:
            return "gray";
    }
}
