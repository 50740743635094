import React from "react";
import * as S from "./styles";
import {
  Button, Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent, ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useAppSelector } from '../../redux/store';

interface Props {
  open: boolean;
  onClose(): void;
  onRecreate(workspaceId: string, image: string, mcpu: number, memory: number, gpu: number, uid: string, username: string): void;
}

export const HistoryModal: React.FC<Props> = (props) => {

  const {open, onClose, onRecreate} = props;

  const history = useAppSelector(state => state.userState.createdWorkspaces);

  return <Modal isOpen={open} onClose={onClose} size="xl">
    <ModalOverlay/>
    <ModalContent>
      <ModalHeader>Runtimes history</ModalHeader>
      <ModalCloseButton/>
      <ModalBody>
        {history.length === 0
          ? <p>No data</p>
          : <S.Container>
            {history.map((item, idx) => <S.Item key={idx}>
              <p>WorkspaceID: <S.Value>{item.workspaceId}</S.Value></p>
              <p>Image: <S.Value>{item.image}</S.Value></p>
              <p>Pod name: <S.Value>{item.podName}</S.Value></p>
              <p>Specs: <S.Value>CPU={item.mcpu}mcpu</S.Value> | <S.Value>Memory={item.memory}M</S.Value> | <S.Value>GPU={item.gpu}</S.Value></p>
              <p>Requested at: <S.Value>{item.requestedAt}</S.Value></p>
              <p>UID: <S.Value>{item.uid}</S.Value></p>
              <p>Username: <S.Value>{item.username}</S.Value></p>
              <Button
                style={{margin: "auto", display: "block", marginTop: "4px"}}
                onClick={() => onRecreate(item.workspaceId, item.image, item.mcpu, item.memory, item.gpu, item.uid, item.username)}
              >
                Recreate
              </Button>
            </S.Item>)}
          </S.Container>
        }
      </ModalBody>
      <ModalFooter>
        <Button colorScheme='blue' variant="outline" mr={3} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>

};
