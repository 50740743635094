export type GetInstancesResponse = InstanceData[];

export class InstanceData {
    readonly workspaceId: string;
    readonly isInPool: boolean;
    readonly startedAt: Date;
    constructor(
        public readonly namespace: string,
        public readonly podName: string,
        public readonly jobName: string,
        public readonly mcpu: number,
        public readonly memory: number,
        public readonly gpu: number,
        public readonly status: ComputedPodStatus,
        public readonly image: string,
        public readonly labels: { [key: string]: string; },
        public readonly ip: string,
        startedAt: string,
    ) {
        this.workspaceId = this.labels.workspaceId;
        this.isInPool = this.labels.poolInstance !== undefined || this.labels.preparedPool !== undefined || this.labels.preparingPool !== undefined;
        this.startedAt = new Date(startedAt);
    }

    get shortImageName(): string {
        return this.image.split("/").slice(-1)[0].replace(/@.*$/, "");
    }

    static parse(obj: any): InstanceData {
        return new InstanceData(
            obj.namespace,
            obj.podName,
            obj.jobName,
            obj.mcpu,
            obj.memory,
            obj.gpu,
            obj.status,
            obj.image,
            obj.labels,
            obj.ip,
            obj.startedAt,
        );
    }
}

export enum ComputedPodStatus {
    PENDING = "PENDING",
    SCALING = "SCALING",
    PULLING = "PULLING",
    RUNNING = "RUNNING",
    UNKNOWN = "UNKNOWN",
    KILLING = "KILLING",
}
