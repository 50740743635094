import React from 'react';
import * as S from './styles';
import { useAppSelector } from '../../redux/store';
import { Progress } from '@chakra-ui/react';

const RED_THRESHOLD = 0.8;

export const ResourcesList: React.FC = () => {

    const resources = useAppSelector(state => state.miscState.resources);
    const gpuAvailable = useAppSelector(state => state.miscState.backendConfig?.gpuSupport === true);

    if (resources === null) {
        return <></>;
    }

    return <>
        <p>Used namespace resources:</p>
        <S.Table>
            <tbody>
            <tr>
                <S.LabelColumn>CPU</S.LabelColumn>
                <S.ProgressColumn>
                    <Progress
                        value={resources.usedCpu / resources.totalCpu * 100}
                        colorScheme={resources.usedCpu / resources.totalCpu > RED_THRESHOLD ? "pink" : undefined}
                    />
                </S.ProgressColumn>
                <S.LabelColumn>{resources.usedCpu} / {resources.totalCpu}</S.LabelColumn>
            </tr>
            <tr>
                <S.LabelColumn>MEM</S.LabelColumn>
                <S.ProgressColumn>
                    <Progress
                        value={resources.usedMemoryBytes / resources.totalMemoryBytes * 100}
                        colorScheme={resources.usedMemoryBytes / resources.totalMemoryBytes > RED_THRESHOLD ? "pink" : undefined}
                    />
                </S.ProgressColumn>
                <S.LabelColumn>{Math.round(resources.usedMemoryBytes / 1073741824)}GiB
                    / {Math.round(resources.totalMemoryBytes / 1073741824)}GiB</S.LabelColumn>
            </tr>
            {gpuAvailable && <tr>
                <S.LabelColumn>GPU</S.LabelColumn>
                <S.ProgressColumn>
                    <Progress
                        value={resources.usedGpu / resources.totalGpu * 100}
                        colorScheme={resources.usedGpu / resources.totalGpu > RED_THRESHOLD ? "pink" : undefined}
                    />
                </S.ProgressColumn>
                <S.LabelColumn>{resources.usedGpu} / {resources.totalGpu}</S.LabelColumn>
            </tr>}
            <tr>
                <S.LabelColumn>Pods</S.LabelColumn>
                <S.ProgressColumn>
                    <Progress
                        value={resources.usedPods / resources.totalPods * 100}
                        colorScheme={resources.usedPods / resources.totalPods > RED_THRESHOLD ? "pink" : undefined}
                    />
                </S.ProgressColumn>
                <S.LabelColumn>{resources.usedPods} / {resources.totalPods}</S.LabelColumn>
            </tr>
            </tbody>
        </S.Table>
    </>;

};
