import React, { useEffect, useState } from 'react';
import * as S from "./styles";
import {
    Button, Checkbox, Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Slider,
    SliderFilledTrack, SliderThumb,
    SliderTrack, Textarea
} from '@chakra-ui/react';
import { PodFile } from '../../dto/run-instance-request';
import { useAppSelector } from '../../redux/store';

interface Props {
    currentMcpu: number;
    currentMemory: number;
    currentGpu: boolean;
    open: boolean;
    onClose(): void;
    onSave(mcpu: number, memory: number, gpu: boolean, files: PodFile[]): void;
}

const SHOW_FILES_CONTROLS = false;

export const InstanceSettingsModal: React.FC<Props> = (props) => {

    const {currentMcpu, currentMemory, currentGpu, open, onClose, onSave} = props;

    const gpuAvailable = useAppSelector(state => state.miscState.backendConfig?.gpuSupport === true);

    const [mcpu, setMcpu] = useState(currentMcpu);
    const [memory, setMemory] = useState(currentMemory);
    const [gpu, setGpu] = useState(currentGpu);
    const [files, setFiles] = useState<PodFile[]>([]);

    useEffect(() => {
        if (open) {
            setMcpu(currentMcpu);
            setMemory(currentMemory);
            setGpu(currentGpu);
        }
    }, [open]);

    function save() {
        onSave(mcpu, memory, gpu, files);
    }

    function cancel() {
        setMcpu(currentMcpu);
        setMemory(currentMemory);
        onClose();
    }

    function addNewFile() {
        const file: PodFile = {
            filename: `myfile-${files.length}.txt`,
            content: "",
        };
        setFiles([file, ...files]);
    }

    function removeFile(index: number) {
        const updatedFiles = [...files];
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles);
    }

    function updateFileName(index: number, value: string) {
        setFiles(files.map((file, idx) => {
            if (idx === index) {
                return {
                    ...file,
                    filename: value,
                };
            } else {
                return file;
            }
        }));
    }

    function updateFileContent(index: number, value: string) {
        setFiles(files.map((file, idx) => {
            if (idx === index) {
                return {
                    ...file,
                    content: value,
                };
            } else {
                return file;
            }
        }));
    }

    return <Modal isOpen={open} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent>
            <ModalHeader>Environment resources</ModalHeader>
            <ModalCloseButton/>
            <ModalBody>
                <S.ResourcesAdvice>
                    Recommended settings: 2000mcpu / 4GiB
                </S.ResourcesAdvice>

                <p>mCPU: <span style={{color: mcpu < 1500 ? "coral" : undefined}}>{mcpu}</span></p>
                <Slider min={1000} max={4000} step={50} defaultValue={mcpu} onChange={setMcpu}>
                    <SliderTrack>
                        <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                </Slider>

                <p>RAM: <span style={{color: memory < 2000 ? "coral" : undefined}}>{memory}MiB</span></p>
                <Slider min={1500} max={8000} step={100} defaultValue={memory} onChange={setMemory}>
                    <SliderTrack>
                        <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                </Slider>

                <Checkbox defaultChecked={gpu} checked={gpu} onChange={() => setGpu(!gpu)} disabled={!gpuAvailable}>
                    Use GPU {gpuAvailable ? "" : "(N/A for this environment)"}
                </Checkbox>

                <S.MemoryInfo>({memory - 344}MiB of it will be allocated for the IDE)</S.MemoryInfo>

                {SHOW_FILES_CONTROLS && <>
                    <Button onClick={addNewFile} style={{margin: "auto", display: "block"}}>
                        Add a new file
                    </Button>

                    {files.map((file, idx) => <S.FileContainer key={idx}>
                        <Input
                            value={file.filename}
                            placeholder="File name"
                            onChange={e => updateFileName(idx, e.target.value)}
                        />
                        <Textarea
                            value={file.content}
                            placeholder="File content"
                            onChange={e => updateFileContent(idx, e.target.value)}
                        />
                        <Button onClick={() => removeFile(idx)}>
                            Remove file
                        </Button>
                    </S.FileContainer>)}
                </>}
            </ModalBody>
            <ModalFooter>
                <Button colorScheme='blue' variant="outline" mr={3} onClick={cancel}>
                    Cancel
                </Button>
                <Button colorScheme='blue' mr={3} onClick={save}>
                    Save {(memory < 2000 || mcpu < 1500) && "and pray it will work"}
                </Button>
            </ModalFooter>
        </ModalContent>
    </Modal>;

};
