import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {InstanceData} from "../../dto/get-instances-response";
import {RunInstanceRequest} from "../../dto/run-instance-request";
import {RunInstanceResponse} from "../../dto/run-instance-response";
import {StopInstanceRequest} from "../../dto/stop-instance-request";

interface InstancesState {
    runningInstances: InstanceData[] | null;
    failedToLoad: boolean;
    creatingInstance: boolean;
}

const initialState: InstancesState = {
    runningInstances: null,
    failedToLoad: false,
    creatingInstance: false,
};

const slice = createSlice({
    name: "instances",
    initialState,
    reducers: {
        loadInstances: () => {},
        onInstancesLoaded: (state, action: PayloadAction<InstanceData[]>) => {
            state.runningInstances = action.payload;
            state.failedToLoad = false;
        },
        onInstancesLoadingFailure: (state) => {
            state.failedToLoad = true;
            state.runningInstances = null;
        },
        runInstance: (state, action: PayloadAction<RunInstanceRequest>) => {
            state.creatingInstance = true;
        },
        onInstanceStarted: (state, action: PayloadAction<OnInstanceStartedActionPayload>) => {
            state.creatingInstance = false;
        },
        onInstanceStartFailure: (state) => {
            state.creatingInstance = false;
        },
        stopInstance: (state, action: PayloadAction<StopInstanceRequest>) => {},
        onInstanceStopped: () => {},
    },
});

export const instancesReducer = slice.reducer;
export const instancesActions = slice.actions;

export type OnInstanceStartedActionPayload = {workspaceId: string, connectionLink: string};
