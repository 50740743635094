import React, {useEffect} from 'react';
import * as S from "./styles";
import {Header} from "../Header/Header";
import {InstanceControls} from "../InstanceControls/InstanceControls";
import {InstancesList} from "../InstancesList/InstancesList";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {miscActions} from "../../redux/reducers/misc.reducer";
import {createStandaloneToast} from "@chakra-ui/react";
import {RecentImagesList} from "../RecentImagesList/RecentImagesList";
import { ConfigInfo } from '../ConfigInfo/ConfigInfo';

export const { ToastContainer, toast } = createStandaloneToast();

export function AppRoot() {

    const dispatch = useAppDispatch();
    const showAdvancedElements = useAppSelector(state => state.miscState.showAdvancedComponents);

    useEffect(() => {
        dispatch(miscActions.init());
    }, []);

    return (
        <S.Layout>
            <Header/>
            <S.Main>
                <InstanceControls/>
                <InstancesList/>
                {showAdvancedElements && <RecentImagesList/>}
                {showAdvancedElements && <ConfigInfo/>}
            </S.Main>
            <ToastContainer/>
        </S.Layout>
    );

}
