import styled from 'styled-components';

export const Container = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
`;

export const Item = styled.div`
  padding: 8px;
  border: 1px solid whitesmoke;
  border-radius: 16px;
`;

export const Value = styled.code`
  //display: inline-block;
  font-size: 0.9em;
  color: white;
  background-color: #1D232C;
  padding: 4px;
  border-radius: 4px;
`;
